import './sass/reset.scss';
import './sass/styles.scss';
import './scripts/scripts';
import React from 'react';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import Copyright from './components/Copyright/Copyright'; 
import Nav from './components/Nav/Nav';
import MastHead from './components/MastHead/MastHead';
import Blog from './components/Blog/Blog'; 
import Portfolio from './components/Portfolio/Portfolio';

const App = () => {
    return (
        <div className="App">
            <Nav />
            <MastHead />
            <Portfolio />
            <Blog />
           
            <About />
            <Contact />
            <Footer />
            <Copyright />
        </div>
    );
}

export default App;
