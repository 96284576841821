import React from 'react'

const Copyright = () => {
    return (
        <>
            {/* Copyright Section*/}
            <div className="copyright py-4 text-center text-white">
                <div className="container"><small>Copyright &copy; alacom LLC 2024</small></div>
            </div>
        </>
    )
}

export default Copyright