import React from 'react'
import BlogPost from '../BlogPost/BlogPost'

const Blog = () => {
    return (
        <>
            {/* Blog Section*/}
            <section className="page-section blog" id="blog">
                <div className="container">
                    {/* Blog Section Heading*/}
                    <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">Blog</h2>
                    {/* Icon Divider*/}
                    <div className="divider-custom">
                        <div className="divider-custom-line"></div>
                        <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                        <div className="divider-custom-line"></div>
                    </div>
                    {/* Blog Grid Items*/}
                    <div className="row justify-content-center">
                    <BlogPost/>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Blog