import React from 'react'
import cabin from '../../assets/img/portfolio/cabin.png';
import cake from '../../assets/img/portfolio/cake.png';
import circus from '../../assets/img/portfolio/circus.png';
import game from '../../assets/img/portfolio/game.png';
import safe from '../../assets/img/portfolio/safe.png';
import submarine from '../../assets/img/portfolio/submarine.png';
import PortfolioModal from '../PortfolioModal/PortfolioModal';

const Portfolio = () => {
    const products = ["IoT Edge Development", "Web Development", "Windows Application Development", "Database Development", "AI & Machine Learning", "Moble Development"]; // Example product IDs

    return (
        <>
            {/* Portfolio Section*/}
            <section className="page-section portfolio" id="portfolio">
                <div className="container">
                    {/* Portfolio Section Heading*/}
                    <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">Services</h2>
                    {/* Icon Divider*/}
                    <div className="divider-custom">
                        <div className="divider-custom-line"></div>
                        <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                        <div className="divider-custom-line"></div>
                    </div>
                    {/* Portfolio Grid Items*/}
                    {products.map((product, index) => (
                          <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal1">
                          <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                              <div className="portfolio-item-caption-content text-center text-white"><i className="fas fa-plus fa-3x"></i></div>
                          </div>
                          <span className="portfolio-item">{product}</span>
                      </div> 
                    ))}
                </div>
            </section>

            {/* Hiddem Modals */}
            <PortfolioModal id='portfolioModal1' image={cabin} />
            <PortfolioModal id='portfolioModal2' image={cake} />
            <PortfolioModal id='portfolioModal3' image={circus} />
            <PortfolioModal id='portfolioModal4' image={game} />
            <PortfolioModal id='portfolioModal5' image={safe} />
            <PortfolioModal id='portfolioModal6' image={submarine} />
        </>
    )
}

export default Portfolio