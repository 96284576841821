import React from 'react'

const About = () => {
    return (
        <>
            {/* About Section*/}
            <section className="page-section bg-primary text-white mb-0" id="about">
                <div className="container">
                    {/* About Section Heading*/}
                    <h2 className="page-section-heading text-center text-uppercase text-white">About</h2>
                    {/* Icon Divider*/}
                    <div className="divider-custom divider-light">
                        <div className="divider-custom-line"></div>
                        <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                        <div className="divider-custom-line"></div>
                    </div>
                    {/* About Section Content*/}
                    <div className="row">
                        <div className="col-lg-4 ms-auto"><p className="lead">alacom LLC is a boutique software development specializing in helping you reach more customers and increase your revenue.</p></div>
                        <div className="col-lg-4 me-auto"><p className="lead">Contact us today for a FREE no obligation estimate about your project and goals and learn more about what we have to offer.</p></div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default About