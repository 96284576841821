import './BlogPost.css';

const BlogPost = () => {
    // Define the blog post details
    const postDetails = [{
        title: "DATA SCIENCE SALON SEA", 
        eventDetails: "APPLYING AI & MACHINE LEARNING TO RETAIL & ECOMMERCE",
        location: "SEATTLE + ON-DEMAND",
        date: "January 24, 2024",
        author: "Alan Gajadhar",
        content: ""
    }, {
        title: "AI Summit West",
        eventDetails: "DISCOVER ADVANCES IN DEEP LEARNING, ADVANCED ML AND THE LATEST REAL-WORLD APPLICATIONS OF AI",
        location: "Santa Ana, CA (Convention Center)",
        date: "February 12, 2024",
        author: "Alan Gajadhar",
        content: ""
    }, {
        title: "Google Next 24",
        eventDetails: "OVER THREE EPIC DAYS, JOIN US FOR VISIONARY KEYNOTES, THE LATEST TECHNOLOGY INNOVATIONS, AND LIVE SESSIONS ON EVERYTHING FROM GENERATIVE AI TO SECURITY.",
        location: "Las Vegas, NV (Mandalay Bay)",
        date: "April 10th, 2024",
        author: "Alan Gajadhar",
        content: ""
    }, {
        title: "Binc 24",
        eventDetails: "BINC 2024 IS THE MUST-ATTEND CONVENTION FOR INVESTING EDUCATION!",
        location: "Chicago, IL (Convention Center)",
        date: "April 15th, 2024",
        author: "Alan Gajadhar",
        content: ""
    }, {
        title: "Consensus 24 by Coin Desk",
        eventDetails: "10 YEARS OF DECENTRALIZING THE FUTURE - DIALOG, DISCOVERY & DEALMAKINGTION!",
        location: "Austin, TX (Convention Center)",
        date: "May 28, 2024",
        author: "Alan Gajadhar",
        content: ""
    }, {
        title: "Cloud Fest USA 24",
        eventDetails: "CLOUDFEST IS THE #1 INTERNET INFRASTRUCTURE EVENT IN THE WORLD, CONNECTING THE GLOBAL CLOUD COMPUTING INDUSTRY",
        location: "Austin, TX (Convention Center)",
        date: "Jun 5, 2024",
        author: "Alan Gajadhar",
        content: ""
    }];

    return (
        <div className="container">
            {postDetails.map((postDetail, index) => (
                <div className="blog-post">
                    <div className="meta-data">
                        <h3>{postDetail.title}</h3>
                        <p><strong>Date:</strong> {postDetail.date}</p>
                        <p><strong>Author:</strong> {postDetail.author}</p>
                    </div>
                    <div className="content">

                        <h4> {postDetail.eventDetails} </h4>
                        <p><strong>Location:</strong> {postDetail.location}</p>
                        <p>{postDetail.content}</p>
                    </div>
                </div>
            ))}
        </div>




        // <div className="container">
        //     <div className="blog-post">
        //         <div className="meta-data">
        //             <h3>{postDetails.title}</h3>
        //             <p><strong>Date:</strong> {postDetails.date}</p>
        //             <p><strong>Author:</strong> {postDetails.author}</p>
        //         </div>
        //         <div className="content">

        //         <h4> {postDetails.eventDetails} </h4>
        //             <p><strong>Location:</strong> {postDetails.location}</p>
        //             <p>{postDetails.content}</p>
        //         </div>
        //     </div>
        //     <div className="blog-post">
        //         <div className="meta-data">
        //             <h3>AI Summit West</h3>
        //             <p><strong>Date:</strong> {postDetails.date}</p>
        //             <p><strong>Author:</strong> {postDetails.author}</p>
        //         </div>
        //         <div className="content">              
        //         <h4> DISCOVER ADVANCES IN DEEP LEARNING, ADVANCED ML AND THE LATEST REAL-WORLD APPLICATIONS OF AI </h4>
        //             <p><strong>Location:</strong> Santa Ana, CA</p>
        //             <p>{postDetails.content}</p>
        //         </div>
        //     </div>

        //     <div className="blog-post">
        //         <div className="meta-data">
        //             <h3>Google Next 24</h3>
        //             <p><strong>Date:</strong> {postDetails.date}</p>
        //             <p><strong>Author:</strong> {postDetails.author}</p>
        //         </div>
        //         <div className="content">              
        //         <h4> DISCOVER ADVANCES IN DEEP LEARNING, ADVANCED ML AND THE LATEST REAL-WORLD APPLICATIONS OF AI </h4>
        //             <p><strong>Location:</strong> Santa Ana, CA</p>
        //             <p>{postDetails.content}</p>
        //         </div>
        //     </div>

        //     <div className="blog-post">
        //         <div className="meta-data">
        //             <h3>BINC 24</h3>
        //             <p><strong>Date:</strong> {postDetails.date}</p>
        //             <p><strong>Author:</strong> {postDetails.author}</p>
        //         </div>
        //         <div className="content">              
        //         <h4> BINC 24 </h4>
        //             <p><strong>Location:</strong> Santa Ana, CA</p>
        //             <p>{postDetails.content}</p>
        //         </div>
        //     </div>

        //     <div className="blog-post">
        //         <div className="meta-data">
        //             <h3>Consensus Crypto Convention</h3>
        //             <p><strong>Date:</strong> {postDetails.date}</p>
        //             <p><strong>Author:</strong> {postDetails.author}</p>
        //         </div>
        //         <div className="content">              
        //         <h4> DISCOVER ADVANCES IN DEEP LEARNING, ADVANCED ML AND THE LATEST REAL-WORLD APPLICATIONS OF AI </h4>
        //             <p><strong>Location:</strong> Santa Ana, CA</p>
        //             <p>{postDetails.content}</p>
        //         </div>
        //     </div>

        //     <div className="blog-post">
        //         <div className="meta-data">
        //             <h3>Cloud Fest USA</h3>
        //             <p><strong>Date:</strong> {postDetails.date}</p>
        //             <p><strong>Author:</strong> {postDetails.author}</p>
        //         </div>
        //         <div className="content">              
        //         <h4> DISCOVER ADVANCES IN DEEP LEARNING, ADVANCED ML AND THE LATEST REAL-WORLD APPLICATIONS OF AI </h4>
        //             <p><strong>Location:</strong> Santa Ana, CA</p>
        //             <p>{postDetails.content}</p>
        //         </div>
        //     </div>
        // </div>
    );
};

export default BlogPost;